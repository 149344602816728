/*ESTILOS GLOBALES*/
:root {
    --tsverde: rgba(12,116,117,1);
}

html, body {
    background-color: #f8f8fb !important;
}

.badge {
    position: absolute;
    left: 210px;
    top:15px;
    
}

.tverde {
    color: var(--tsverde) !important;
}

.twhite {
    color: white !important;
}

.fverde {
    background-color: var(--tsverde) !important;
    color: white !important;
}

.blockquote {
    border-left: 0.25rem solid var(--tsverde);
}

//HEADER GOB
.inverse2 {
    background-color: #611232 !important;
    border-color: transparent !important;
}

.tab-content {
    box-shadow: none!important;
}
/* FOOTER*/

.main-footer2 {
    background-color: #611232;
    min-height: 56px;
    color: #FFF;
    padding: 0px 0 0px;
    text-align: left;
    font-weight: 300;
}

.main-footer2 .list-info {
    line-height: auto;
    color: white;
    background-size: cover;
    background-position: bottom;
    padding: 30px 10px 10px;
}

.main-footer2 .list-info h5 {
    font-weight: 300;
    margin-bottom: 20px;
}

.main-footer2 .list-info ul {
    margin: 0 0 60px;
    padding: 0;
}

.main-footer2 .list-info ul li {
    list-style-type: none;
    margin: 0 0 2px;
    padding: 0;
    line-height: 110%;
}

.main-footer2 .list-info a {
    color: #FFF;
    font-size: 14px;
    text-decoration: none;
}

.main-footer2 .list-info p {
    margin-bottom: 15px;
}

.main-footer2 p {
    margin: 0;
    font-size: 14px;
    line-height: 136%;
    height: auto;
    position: static;
}

.footer-pleca2 {
    background-color: white;
    background-image: url(https://framework-gb.cdn.gob.mx/gobmx/img/pleca.svg);
    background-repeat: repeat-x;
}


.side-nav .logo-wrapper {
    border-bottom: none!important;
}

.side-nav {
    width: 16rem!important;
}

.side-nav .sidenav-bg {
    width: 16rem!important;
}

.page-wrapper.toggled .sidebar {
    left: -300px;
}

// angular perfect scrollbar style
.sidebar .ps.ps--scrolling-y>.ps__rail-y,
.sidebar>.ps.ps--scrolling-x>.ps__rail-x,
.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
    background-color: transparent !important;
}

.sidebar .ps.ps--active-y>.ps__rail-y:hover,
.sidebar>.ps.ps--active-y>.ps__rail-y.ps--clicking {
    width: 10px !important;
}

.ps__thumb-y {
    background-color: #fff !important;
    width: 6px!important;
    border-radius: 0px!important;
    opacity: .5;
}

.modal-dialog .modal-content {
    border-radius: 10px !important;
}

.tab-content {
    box-shadow: none!important;
}

.ldim{
    max-width: 80%!important;
}

@media (min-width: 320px) and (max-width: 480px) {
    .ldim{
        max-width: 100%!important;
    }
  }

.amp{
    margin-bottom: 5px;
    background: linear-gradient(to bottom right, #D9D9D9, #818D94);
}


//Modal 

.modal-backdrop{
    background-color: #1e4147e6!important;
}

.md_custom{
    background-color:#1e4147e6 ;
    color: #F3F5F4;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    }
    
    .md_con_custom{
    background:#F3F5F4;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    }
    
    /*SE MUEVE LA CLASE DE .cenicon DEL CSS LOCAL Y LA NUEVA CLASE .sinsombra*/
    .cenicon{
        text-align: center;
        position: absolute;
        justify-content: center;
        width: 100%;
        top: 82%;
    }

    .cenicon2 {
        text-align: center;
        position: relative;
        justify-content: center;
        width: 100%;
    }

    .sinsombra {
        box-shadow: none !important;
        background-color: #1b8283 !important;
    }
    /*TERMINAN BOTONES PARA CARRUSEL */


    .md-form .mdb-select .mdb-select-label {
        color: var(--tsverde) !important;
        font-weight: bold!important;
        font-size: 18px !important;
    }

.hrcolor {
    border-color: var(--tsverde);
}

.swiper-pagination-bullet-active {
    opacity: 1;
    background: var(--tsverde) !important;
  }

.white-fondo {
    background-color: #f8f8fb !important;
}
mdb-tabset .white-fondo .active a {
    color: #f8f8fb !important;

}
    
.btn {
    box-shadow: none !important;
}

/*VIDEOS CAMPOS FORMATIVOS*/
.cf1 {
    color:black;
}
.cf2 {
    color:#4aa1af;
    margin-top: -15px;
}